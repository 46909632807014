import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG } from '../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton, Pagination } from '@mui/material'
import useAuthContext from '../../../Auth/AuthContext'
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images'
import ReactTableWithPagention from '../../../../_metronic/partials/widgets/React_Table/ReactTableWithPagention'
import moment from 'moment'

const AdminLogs = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [Items, setItems] = useState([])
    const { check_role } = useAuthContext()
    const navigate = useNavigate()
    const [inputValue, setInputValue] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [type, setType] = useState(1)
    const [check, setCheck] = useState({
        browse: false,
    })



    const get_data = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get(`/admin-user-activities?page=${currentPage}`, {
            params: { name: inputValue, type: type },
        })

        setItems(data.data)
        setTotalPages(data.meta.last_page)
        setLoading(false)
        console.log('123', Items[0]);

    }



    const columns = [

        {
            Header: intl.formatMessage({ id: 'Table.User' }),
            accessor: (row) => `${row.user?.name} ${row.user?.id}`,
            Cell: (row) =>
                row.row.original.user && (
                    <a href={`/Users/Edit-Users/${row.row.original.user.id}`} target='_blank'>
                        <div className='d-flex align-items-center ps-3'>
                            <Images img={row.row.original.user.img} name={row.row.original.user.name} />
                            <div className='flex-grow-1'>
                                <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                                    {row.row.original.user.name}
                                </div>
                                <span className='text-muted d-block fw-semibold text-start'>
                                    #{row.row.original.user.id}
                                </span>
                            </div>
                        </div>
                    </a>
                ),
            // Define a custom filter function
            filter: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const user = row.values[id] // id will be 'user' which is the accessor
                    return user.toLowerCase().includes(filterValue.toLowerCase()) // Search on concatenated string
                })
            },
        },
        {
            Header: intl.formatMessage({ id: 'Table.Model_id' }),
            accessor: 'model_id',
            Cell: (row) => <div className=' w-100 flex-center'>
                
                # {row.row.original.model_id}

            </div>,
        },
        {
            Header: intl.formatMessage({ id: 'Table.Model' }),
            accessor: 'model',
            Cell: (row) => <div className=' w-100 flex-center'>
                {row.row.original.model_type}
                
            </div>,
        },
        {
            Header: intl.formatMessage({ id: 'Table.request_type' }),
            accessor: 'request_type',
            Cell: (row) => <div className=' w-100 flex-center'>
                {row.row.original.request_type === 'updated' ?  
                    <span className='btn btn-bg-primary text-white'>{row.row.original.request_type}</span>
                :
                row.row.original.request_type === 'created' ?
                    <span className='btn btn-bg-success text-white '>{row.row.original.request_type}</span>
                :
                row.row.original.request_type === 'deleted' ?
                    <span className='btn btn-bg-danger text-white '>{row.row.original.request_type}</span>
                :
                <span className='btn btn-bg-secondary text-dark '>{row.row.original.request_type}</span>
                }
            </div>,
        },
        {
            Header: intl.formatMessage({ id: 'Table.Date' }),
            accessor: 'created_at',
            Cell: ({ row }) => <div className='w-100 flex-center'>{moment(row.original.created_at).format("YYYY-MM-DD hh:mm:ss A")} </div>,
        },
        {
            Header: intl.formatMessage({ id: 'Table.Action' }),
            Cell: (row) => (
                <div className='d-flex flex-center'>
                    {check.browse && (
                        <Link
                            to={`/Logs-View/${row.row.original.id}`}
                            className='btn me-2  btn-light-primary'
                        >
                            {' '}
                            {intl.formatMessage({ id: 'Table.View' })}{' '}
                        </Link>
                    )}

                </div>
            ),
        },
    ]


    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1)
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1)
    }

    useEffect(() => {
        get_data()
    }, [inputValue, currentPage, type])


    useEffect(() => {
        setCheck({
            browse: check_role('browse-logs'),
        })

        if (!check_role('browse-logs')) {
            navigate('/')
        }
        get_data()
    }, [])

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
        >
            <div className='mb-5 mt-5 d-flex flex-stack'>
                <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                    <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                        {intl.formatMessage({ id: 'Menu.logs' })}
                    </h1>
                    <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                        <li className='breadcrumb-item text-muted'>{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        <li className='breadcrumb-item'>
                            <span className='bullet bg-gray-400 w-5px h-2px'></span>
                        </li>
                        <li className='breadcrumb-item text-muted'>{intl.formatMessage({ id: 'Menu.logs' })}</li>
                    </ul>
                </div>
            </div>



            {!loading ? (
                <div className='d-none d-lg-block'>
                    <ReactTableWithPagention
                        columns={columns}
                        data={Items}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        customComponent={<></>}
                    />
                </div>
            ) : (
                <div className='row'>
                    <div className='col-12'>
                        <Skeleton
                            variant='rounded'
                            animation='wave'
                            sx={{ width: '100%', height: '90vh', marginTop: '1vh' }}
                        />
                    </div>
                </div>
            )}
        </motion.nav>
    )
}

export default AdminLogs
