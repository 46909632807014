import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton, Pagination } from '@mui/material'
import useAuthContext from '../../../Auth/AuthContext'
import 'flatpickr/dist/themes/light.css'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

const HomeLoyaltyPoint = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const { check_role } = useAuthContext()
    const navigate = useNavigate()
    const [Date, setDate] = useState(moment().format('YYYY-MM-01'))
    const [check, setCheck] = useState({
        browse: false,
        add: false,
        show: false,
        edit: false,
        delete: false,
    })


    const getData = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get('/admin-user-tasks', {
                params: {
                    date: Date
                }
            })
            setItems(data)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const deleteCategory = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    await axiosInstance.delete(`/admin-user-tasks/${id}`)
                    getData()
                } catch (error) {
                    Swal.showValidationMessage(`Request failed: ${error}`)
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Deleted!', 'Your category has been deleted.', 'success')
            }
        })
    }

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.ID' }),
            accessor: 'id',
            Cell: ({ row }) => <div className='w-100 flex-center'># {row.original.id}</div>,
        },

        {
            Header: intl.formatMessage({ id: 'Table.DescriptionEN' }),
            accessor: 'name_en',
            Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.name_en}</div>,
        },
        {
            Header: intl.formatMessage({ id: 'Table.To' }),
            accessor: 'to',
            Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.task_to_type} :   {row.original.item?.name_en} {row.original.cat?.name_en}</div>,
        },
      
        {
            Header: intl.formatMessage({ id: 'Table.Type' }),
            accessor: 'task_type',
            Cell: ({ row }) =>
            <div className='w-100 flex-center'>
                {row.original.task_type === 'buy_with_count' && 'Buy With Count'}
                {row.original.task_type === 'buy_with_total' && 'Buy With Total'}
                {row.original.task_type === 'review' && 'Add Review'}
            </div>,
        },
        {
            Header: intl.formatMessage({ id: 'Table.Points' }),
            accessor: 'points',
            Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.points}</div>,
        },
        {
            Header: 'Count/Total',
            accessor: 'count',
            Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.count > 0 ?'Count : ' + row.original.count : 'Total : ' + row.original.total}</div>,
        },
        {
            Header: intl.formatMessage({ id: 'Table.Action' }),
            Cell: ({ row }) => (
                <div className='d-flex flex-center'>
                    {check.edit && (
                        <Link to={`/Edit-LoyaltyPoints/${row.original.id}`} className='btn me-2 btn-light-primary'>
                            {intl.formatMessage({ id: 'Table.Edit' })}
                        </Link>
                    )}


                    {check.delete && (
                        <button
                            onClick={() => deleteCategory(row.original.id)}
                            className='btn me-2 btn-light-danger'
                        >
                            {intl.formatMessage({ id: 'Table.Delete' })}
                        </button>
                    )}
                </div>
            ),
        },
    ]

    useEffect(() => {
        setCheck({
            browse: check_role('browse-LoyaltyPoints'),
            add: check_role('add-LoyaltyPoints'),
            show: check_role('show-LoyaltyPoints'),
            edit: check_role('edit-LoyaltyPoints'),
            delete: check_role('delete-LoyaltyPoints'),
        })

        if (!check_role('browse-LoyaltyPoints')) {
            navigate('/')
        }
        getData()
    }, [Date])

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
        >
            <div className='mb-5 mt-5 d-flex flex-stack'>
                <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                    <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                        {intl.formatMessage({ id: 'Menu.LoyaltyPoint' })}
                    </h1>
                    <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                        <li className='breadcrumb-item text-muted'>{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        <li className='breadcrumb-item'>
                            <span className='bullet bg-gray-400 w-5px h-2px'></span>
                        </li>
                        <li className='breadcrumb-item text-muted'>
                            {intl.formatMessage({ id: 'Menu.LoyaltyPoint' })}
                        </li>
                    </ul>
                </div>
                <div className='d-flex align-items-center'>
                    {check.add && (
                        <div className='col-auto'>
                            <Link to={`/Add-LoyaltyPoints`} type='button' className='btn btn-primary py-5'>
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {intl.formatMessage({ id: 'LoyaltyPoints.Create' })}
                            </Link>
                        </div>
                    )}
                    <div className='col-auto ms-2 '>
                        <Flatpickr
                            value={Date}
                            onChange={([Date]) => {
                                setDate(moment(Date).format('YYYY-MM-DD'))
                            }}
                            className='form-control py-4 text-center pt-5 bg-secondary'
                            placeholder='Pick date'
                        />
                    </div>
                </div>
            </div>

            {/* Table for large screens */}
            <div className='d-none d-md-block'>
                {!loading ? (
                    <ReactTable columns={columns} data={items} customComponent={<></>} />
                ) : (
                    <Skeleton
                        variant='rounded'
                        animation='wave'
                        sx={{ width: '100%', height: '90vh', marginTop: '1vh' }}
                    />
                )}
            </div>
        </motion.nav>
    )
}

export default HomeLoyaltyPoint
