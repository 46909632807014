import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import useAuthContext from '../../../Auth/AuthContext'
import axiosInstance from '../../../api/axios'
import {motion} from 'framer-motion'
import {Box, FormControl, MenuItem, Select, Skeleton, Tab, Tabs} from '@mui/material'
import {OwnerChartMonth} from '../../../../_metronic/partials/widgets/charts/DashboardCharts/OwnerChartMonth'
import {useNavigate} from 'react-router-dom'
import {
  FaArrowDown,
  FaArrowUp,
  FaCheckCircle,
  FaRegQuestionCircle,
  FaRegTimesCircle,
} from 'react-icons/fa'
import {TopUsersByOrders} from './TopUsersByOrders'
import {CustomDateModel} from './CustomDateModel'
import {BarChart} from '../../../../_metronic/partials/widgets/charts/DashboardCharts/BarChart'
import {PieChart} from '../../../../_metronic/partials/widgets/charts/DashboardCharts/pieChart'
import {AnaltyticsProducts} from './AnaltyticsProducts'
import {MdGroups} from 'react-icons/md'

const AnalyticsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const {check_role, formatNumber} = useAuthContext()
  const [tab_btn, setTab_btn] = useState('this_week')
  const [Analytics_tab, setAnalytics_tab] = useState(0)
  const [Customers_tab, setCustomers_tab] = useState(0)
  const [CustomDate, setCustomDate] = useState(null)

  const navigate = useNavigate()

  const types = [
    {id: 'this_week', name: 'this week'},
    {id: 'last_week', name: 'last week'},
    {id: 'this_month', name: 'this month'},
    {id: 'last_month', name: 'last month'},
    {id: 'this_year', name: 'this year'},
  ]

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-analytics', {
      params: {type: tab_btn, report_type: 'normal'},
    })
    console.log(data);
    
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    if (!check_role('browse-analytics')) {
      navigate('/')
    }
    get_data()
  }, [tab_btn])

  
  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.analytics'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <li className='breadcrumb-item text-muted'>
                {intl.formatMessage({id: 'Menu.Home'})}
              </li>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.analytics'})}
            </li>
          </ul>
        </div>
        <div className='col-6'>
          <div className='row align-items-center'>
            <div className='col-6'>
              <button
                type='button'
                onClick={(e) => setCustomDate(true)}
                className='btn btn-primary w-100 py-4'
              >
                {/* <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' /> */}
                {intl.formatMessage({id: 'Table.CustomDate'})}
              </button>
            </div>
            <div className='col-6'>
              <div className='card  col-md-12 my-3 col-sm-12' style={{borderRadius: '8px'}}>
                <FormControl fullWidth>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    name='type'
                    onChange={(e) => {
                      setTab_btn(e.target.value)
                    }}
                    value={tab_btn}
                  >
                    {types.map((row, key) => (
                      <MenuItem key={key} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!loading && data ? (
        <>
          <div className='row px-3'>
            <div className='card'>
              <div className='row flex-between'>
                <div className='col-4'>
                  <div
                    className='row align-items-center mt-5 mb-3'
                    style={{borderRight: ' solid 0.5px #d8e2ef'}}
                  >
                    <div>
                      <span className='dahsboard_first_section_div p-0 fs-1'>
                        <FaCheckCircle style={{color: '#00d27a'}} />
                      </span>
                      <span className='text-black-500 pt-1 ms-1 fw-semibold fs-6'>
                        {' '}
                        Completed Orders
                      </span>
                    </div>
                    <div className='row flex-between align-items-center  px-5'>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1  my-4 text-gray-700  '>
                        {' '}
                        {data?.success_orders_count ?? 0} Order
                      </p>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1 text-end   my-4 text-gray-700   '>
                        {' '}
                        {data?.success_orders ? formatNumber(data.success_orders) : 0} SAR
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <div
                    className='row align-items-center mt-5 mb-3'
                    style={{borderRight: ' solid 0.5px #d8e2ef'}}
                  >
                    <div>
                      <span className='dahsboard_first_section_div p-0 fs-1'>
                        <FaRegQuestionCircle style={{color: '#dfa100'}} />
                      </span>
                      <span className='text-black-500 pt-1 ms-1 fw-semibold fs-6'>
                        {' '}
                        Pending Orders
                      </span>
                    </div>
                    <div className='row flex-between align-items-center  px-5'>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1  my-4 text-gray-700  '>
                        {' '}
                        {data?.pending_orders_count ?? 0} Order
                      </p>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1  text-end my-4 text-gray-700   '>
                        {' '}
                        {data?.pending_orders ? formatNumber(data.pending_orders) : 0} SAR
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='row align-items-center mt-5 mb-3'>
                    <div>
                      <span className='dahsboard_first_section_div p-0 fs-1'>
                        <FaRegTimesCircle style={{color: '#ff0000'}} />
                      </span>
                      <span className='text-black-500 pt-  ms-1 fw-semibold fs-6'>
                        {' '}
                        Failed Orders
                      </span>
                    </div>
                    <div className='row flex-between align-items-center  px-5'>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1  my-4 text-gray-700  '>
                        {' '}
                        {data?.failed_orders_count ?? 0} Order
                      </p>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1 text-end  my-4 text-gray-700   '>
                        {' '}
                        {data?.failed_orders ? formatNumber(data.failed_orders) : 0} SAR
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row px-3 my-8'>
            <div className='card'>
              {/* Group Section */}
              <div className='row flex-between'>
                {data?.groups?.map((group, index) => (
                  <div key={index} className='col-4'>
                    <div
                      className='row align-items-center mt-5 mb-3'
                      style={{
                        borderRight: 'solid 0.5px #d8e2ef',
                        borderLeft: group.default === 1 ? 'solid 3px #00d27a' : 'solid 3px #1E90FF',
                      }}
                    >
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className=''>
                          <span className='dahsboard_first_section_div p-0 fs-1'>
                            <MdGroups
                              style={{color: group.default === 1 ? '#00d27a' : '#1E90FF'}}
                            />
                          </span>
                          <span className='text-black-500 pt-1 ms-1 fw-semibold fs-6'>
                            {group.name_en}
                          </span>
                        </div>
                        {group.default === 1 && (
                          <span
                            className='badge bg-secondary me-5'
                            style={{
                              fontSize: '0.85rem',
                              padding: '0.3rem 0.5rem',
                              borderRadius: '4px',
                              marginTop: '5px',
                              color:'black'
                            }}
                          >
                            Default
                          </span>
                        )}
                      </div>

                      <div className='row flex-between align-items-center px-5'>
                        <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1 my-4 text-gray-700'>
                          {group.total_orders_count} Order
                        </p>
                        <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1 text-end my-4 text-gray-700'>
                          {/* {group.total_orders_price} SAR */}

                          {group.total_orders_price ? formatNumber(group.total_orders_price) : 0} SAR

                          
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='row flex-between'>
            <div className='col-6 my-3'>
              <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                <h1 className='page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0'>
                  {intl.formatMessage({id: 'Table.OrdersTotal'})}
                </h1>
              </div>
              <div className='card  my-3'>
                <div className='d-flex flex-wrap overflow-auto' style={{maxHeight: '100px'}}>
                  <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                      <Tabs
                        value={Analytics_tab}
                        aria-label='basic tabs example'
                        variant='scrollable'
                        scrollButtons='auto'
                      >
                        <Tab
                          label='success'
                          onClick={(e) => setAnalytics_tab(0)}
                          sx={{flex: '1 1 33.33%', textAlign: 'center'}}
                        />
                        <Tab
                          label='pending'
                          onClick={(e) => setAnalytics_tab(1)}
                          sx={{flex: '1 1 33.33%', textAlign: 'center'}}
                        />
                        <Tab
                          label='failed'
                          onClick={(e) => setAnalytics_tab(2)}
                          sx={{flex: '1 1 33.33%', textAlign: 'center'}}
                        />
                      </Tabs>
                    </Box>
                  </Box>
                </div>
                {Analytics_tab === 0 && (
                  <OwnerChartMonth
                    names={data.names}
                    type='total'
                    colors={['#11d339']}
                    numbers={data.success_total}
                  />
                )}
                {Analytics_tab === 1 && (
                  <OwnerChartMonth
                    names={data.names}
                    type='total'
                    colors={['#11d339']}
                    numbers={data.pending_total}
                  />
                )}
                {Analytics_tab === 2 && (
                  <OwnerChartMonth
                    names={data.names}
                    type='total'
                    colors={['#11d339']}
                    numbers={data.failed_total}
                  />
                )}
              </div>
            </div>
            <div className='col-6 my-3'>
              <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                <h1 className='page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0'>
                  {intl.formatMessage({id: 'Table.OrdersCount'})}
                </h1>
              </div>
              <div className='card my-3'>
                <div className='d-flex flex-wrap overflow-auto' style={{maxHeight: '100px'}}>
                  <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                      <Tabs
                        value={Analytics_tab}
                        aria-label='basic tabs example'
                        variant='scrollable'
                        scrollButtons='auto'
                      >
                        <Tab
                          label='success'
                          onClick={(e) => setAnalytics_tab(0)}
                          sx={{flex: '1 1 33.33%', textAlign: 'center'}}
                        />
                        <Tab
                          label='pending'
                          onClick={(e) => setAnalytics_tab(1)}
                          sx={{flex: '1 1 33.33%', textAlign: 'center'}}
                        />
                        <Tab
                          label='failed'
                          onClick={(e) => setAnalytics_tab(2)}
                          sx={{flex: '1 1 33.33%', textAlign: 'center'}}
                        />
                      </Tabs>
                    </Box>
                  </Box>
                </div>
                {Analytics_tab === 0 && (
                  <OwnerChartMonth
                    names={data.names}
                    type='count'
                    colors={['#0095e8']}
                    numbers={data.success_numbers}
                  />
                )}
                {Analytics_tab === 1 && (
                  <OwnerChartMonth
                    names={data.names}
                    type='count'
                    colors={['#0095e8']}
                    numbers={data.pending_numbers}
                  />
                )}
                {Analytics_tab === 2 && (
                  <OwnerChartMonth
                    names={data.names}
                    type='count'
                    colors={['#0095e8']}
                    numbers={data.failed_numbers}
                  />
                )}
              </div>
            </div>
          </div>

          <div className=' my-5 d-flex flex-stack'>
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              <h1 className='page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0'>
                {intl.formatMessage({id: 'Menu.Users'})}
              </h1>
            </div>
          </div>

          <div className='row flex-between'>
            <div className='col-6 '>
              <div className='card'>
                <BarChart names={data.names} numbers={data.new_customers_array} />
              </div>
            </div>
            <div className='col-6 '>
              <div className='card'>
                <PieChart numbers={[data.new_customers_count, data.new_orders_count]} />
              </div>
            </div>
          </div>
          <div className='row px-3 my-3'>
            <div className='card'>
              <div className='row flex-between'>
                <div className='col-4'>
                  <div
                    className='row align-items-center mt-5 mb-3'
                    style={{borderRight: ' solid 0.5px #d8e2ef'}}
                  >
                    <div>
                      <span className='dahsboard_first_section_div p-0 fs-1'>
                        <FaCheckCircle style={{color: '#00d27a'}} />
                      </span>
                      <span className='text-black-500 pt-1 ms-1 fw-semibold fs-6'>
                        {' '}
                        {intl.formatMessage({id: 'Table.Top_user_by_order_count'})}
                      </span>
                    </div>
                    <div className='row flex-between align-items-center  px-5'>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1  my-4 text-gray-700  '>
                        {' '}
                        {data?.top_buyer?.total_order_count ?? 0} Order
                      </p>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1 text-end   my-4 text-gray-700   '>
                        {' '}
                        {data?.top_buyer?.total_cash ? formatNumber(data.top_buyer.total_cash) : 0} SAR
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <div
                    className='row align-items-center mt-5 mb-3'
                    style={{borderRight: ' solid 0.5px #d8e2ef'}}
                  >
                    <div>
                      <span className='dahsboard_first_section_div p-0 fs-1'>
                      <FaCheckCircle style={{color: '#00d27a'}} />
                      </span>
                      <span className='text-black-500 pt-1 ms-1 fw-semibold fs-6'>
                        {' '}
                        {intl.formatMessage({id: 'Table.Top_user_by_order_total'})}
                      </span>
                    </div>
                    <div className='row flex-between align-items-center  px-5'>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1  my-4 text-gray-700  '>
                        {' '}
                        {data?.top_customers_order_count?.total_order_count ?? 0} Order
                      </p>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1 text-end   my-4 text-gray-700   '>
                        {' '}
                        {data?.top_customers_order_count?.total_cash ? formatNumber(data.top_customers_order_count.total_cash) : 0} SAR
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='row align-items-center mt-5 mb-3'>
                  <div>
                      <span className='dahsboard_first_section_div p-0 fs-1'>
                      <FaCheckCircle style={{color: '#00d27a'}} />
                      </span>
                      <span className='text-black-500 pt-1 ms-1 fw-semibold fs-6'>
                        {' '}
                        {intl.formatMessage({id: 'Table.new_customersOrders'})}
                      </span>
                    </div>
                    <div className='row flex-between align-items-center  px-5'>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1  my-4 text-gray-700  '>
                        {' '}
                        {data?.new_customer_orders?.total_order_count ?? 0} Order
                      </p>
                      <p className='col-lg-6 col-md-12 col-sm-12 new_font fs-1 lh-1 text-end   my-4 text-gray-700   '>
                        {' '}
                        {data?.new_customer_orders?.total_cash ? formatNumber(data.new_customer_orders.total_cash) : 0} SAR
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card my-3 '>
            <div className='d-flex flex-wrap overflow-auto' style={{maxHeight: '100px'}}>
              <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                  <Tabs
                    value={Customers_tab}
                    aria-label='basic tabs example'
                    variant='scrollable'
                    scrollButtons='auto'
                  >
                    <Tab
                      label={intl.formatMessage({id: 'Table.Top_user_by_order_count'})}
                      onClick={(e) => setCustomers_tab(0)}
                      sx={{flex: '1 1 33.33%', textAlign: 'center'}}
                    />
                    <Tab
                      label={intl.formatMessage({id: 'Table.Top_user_by_order_total'})}
                      onClick={(e) => setCustomers_tab(1)}
                      sx={{flex: '1 1 33.33%', textAlign: 'center'}}
                    />
                    <Tab
                      label={intl.formatMessage({id: 'Table.new_customersOrders'})}
                      onClick={(e) => setCustomers_tab(2)}
                      sx={{flex: '1 1 33.33%', textAlign: 'center'}}
                    />
                  </Tabs>
                </Box>
              </Box>
            </div>
            {Customers_tab === 0 && <TopUsersByOrders data={data.top_buyer.data} />}
            {Customers_tab === 1 && <TopUsersByOrders data={data.top_customers_order_count.data} />}
            {Customers_tab === 2 && <TopUsersByOrders data={data.new_customer_orders.data} />}
          </div>

          <div className=' my-5 d-flex flex-stack'>
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              <h1 className='page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0'>
                {intl.formatMessage({id: 'Menu.Products'})}
              </h1>
            </div>
          </div>
          <AnaltyticsProducts data={data.new_products} />
        </>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
      <CustomDateModel
        handleClose={setCustomDate}
        show={CustomDate}
        setLoading={setLoading}
        loading={loading}
        setData2={setData}
      />
    </motion.nav>
  )
}

export default AnalyticsHome
