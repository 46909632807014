import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton, Pagination } from '@mui/material'
import useAuthContext from '../../../Auth/AuthContext'
import { ImportCats } from './ImportCats'

const CategoriesHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const { check_role } = useAuthContext()
  const navigate = useNavigate()
  const [Import, setImport] = useState(null)
  const [export_loading, setExport_Loading] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
    sub_cat: false,
    import: false,
    export: false,
  })


  const get_exported_data = async (event) => {
    setExport_Loading(true)
    try {
      event.preventDefault()
      const formData = new FormData()
      const response = await axiosInstance.post(`/export-cats`, formData, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      setExport_Loading(false);
    } catch (e) {
      setExport_Loading(false)
      console.log(e);
      
    }
  }
  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Add-Categories' type='button' className='btn btn-primary py-5'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Categories.Create' })}
          </Link>
        </div>
      )}
      <div className='col-12 col-md-3 mb-3 mb-md-0 text-center text-md-start'>
        {check.import && (
          <button
            onClick={(e) => setImport(true)}
            type='button'
            className='btn w-100 py-3 py-md-5 btn-light-primary'
          >
            <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Table.Import' })}
          </button>
        )}
      </div>

      <div className='col-12 col-md-3 mb-3 mb-md-0 text-center text-md-start'>
        {check.export && (
          <button
            onClick={get_exported_data}
            type='button'
            className='btn w-100 py-3 py-md-5 btn-light-primary'
          >
            {!export_loading &&
              <>
                <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' /> 
                {intl.formatMessage({ id: 'Table.Export' })}
              </>
            }
            {export_loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  )

  const getData = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get('/admin-cats')
      setItems(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const deleteCategory = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await axiosInstance.delete(`/admin-cats/${id}`)
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your category has been deleted.', 'success')
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }),
      accessor: 'id',
      Cell: ({ row }) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },

    {
      Header: intl.formatMessage({ id: 'Table.Supplier' }),
      accessor: 'type',
      Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.type ?? '---'}</div>,
    },
    {
      Header: intl.formatMessage({ id: 'Table.DescriptionEN' }),
      accessor: 'name_en',
      Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.name_en}</div>,
    },
    {
      Header: intl.formatMessage({ id: 'Table.DescriptionAR' }),
      accessor: 'name_ar',
      Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.name_ar}</div>,
    },
    {
      Header: intl.formatMessage({ id: 'Table.File' }),
      accessor: 'img',
      Cell: ({ row }) => (
        <div className='symbol symbol-50px'>
          <img
            src={
              row.original.img
                ? process.env.REACT_APP_IMAGE_PATH + row.original.img
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
          />
        </div>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'Table.default_image' }),
      accessor: 'item_img',
      Cell: ({ row }) => (
        <div className='symbol symbol-50px'>
          <img
            src={
              row.original.item_img
                ? process.env.REACT_APP_IMAGE_PATH + row.original.item_img
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
          />
        </div>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'Table.Action' }),
      Cell: ({ row }) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link to={`/Edit-Categories/${row.original.id}`} className='btn me-2 btn-light-primary'>
              {intl.formatMessage({ id: 'Table.Edit' })}
            </Link>
          )}
          {check.sub_cat && (
            <Link to={`/Sub-Categories/${row.original.id}`} className='btn me-2 btn-light-primary'>
              {intl.formatMessage({ id: 'Table.SubCategories' })}
            </Link>
          )}

          {check.delete && (
            <button
              onClick={() => deleteCategory(row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({ id: 'Table.Delete' })}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-categories'),
      add: check_role('add-categories'),
      show: check_role('show-categories'),
      edit: check_role('edit-categories'),
      delete: check_role('delete-categories'),
      import: check_role('import-categories'),
      export: check_role('export-categories'),
      sub_cat: check_role('delete-sub-categories'),
    })

    if (!check_role('browse-categories')) {
      navigate('/')
    }
    getData()
  }, [])

  const filteredItems = items.filter((item) => {
    const search = searchTerm.toLowerCase()
    return (
      item.name_en.toLowerCase().includes(search) ||
      item.name_ar.toLowerCase().includes(search) ||
      item.type.toLowerCase().includes(search)
    )
  })


  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({ id: 'Menu.Categories' })}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({ id: 'Menu.Home' })}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({ id: 'Menu.Categories' })}
            </li>
          </ul>
        </div>
      </div>

      {/* Table for large screens */}
      <div className='d-none d-md-block'>
        {items.length > 0 && !loading ? (
          <ReactTable columns={columns} data={items} customComponent={<CustomDiv />} />
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{ width: '100%', height: '90vh', marginTop: '1vh' }}
          />
        )}
      </div>

      {/* Card view for small screens */}
      <div className='d-block d-md-none'>
        <div className='mb-3'>
          <CustomDiv />
        </div>
        <div className='mb-4'>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({ id: 'Search.Input' })}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {currentItems.length > 0 && !loading ? (
          currentItems.map((item) => (
            <div className='card mb-4 p-3 shadow-lg border-0 rounded-lg' key={item.id}>
              <div className='card-body'>
                <h5 className='card-title fw-bold mb-3'># {item.id}</h5>

                <p className='card-text mb-2'>
                  <strong>{intl.formatMessage({ id: 'Table.Supplier' })}:</strong>{' '}
                  {item.type ?? '---'}
                </p>

                <p className='card-text mb-2'>
                  <strong>{intl.formatMessage({ id: 'Table.DescriptionEN' })}:</strong> {item.name_en}
                </p>

                <p className='card-text mb-3'>
                  <strong>{intl.formatMessage({ id: 'Table.DescriptionAR' })}:</strong> {item.name_ar}
                </p>

                <div className='d-flex align-items-center justify-content-around mb-3'>
                  <div className='text-center'>
                    <strong className='d-block mb-2'>File</strong>
                    <img
                      className='img-fluid img-thumbnail rounded shadow-sm'
                      src={
                        item.img
                          ? process.env.REACT_APP_IMAGE_PATH + item.img
                          : toAbsoluteUrl('media/avatars/300-6.jpg')
                      }
                      alt='File'
                      style={{ maxWidth: '80px' }}
                    />
                  </div>

                  <div className='text-center'>
                    <strong className='d-block mb-2'>Default Image</strong>
                    <img
                      className='img-fluid img-thumbnail rounded shadow-sm'
                      src={
                        item.item_img
                          ? process.env.REACT_APP_IMAGE_PATH + item.item_img
                          : toAbsoluteUrl('media/avatars/300-6.jpg')
                      }
                      alt='Default Image'
                      style={{ maxWidth: '80px' }}
                    />
                  </div>
                </div>

                <div className='d-flex justify-content-between'>
                  {check.edit && (
                    <Link
                      to={`/Edit-Categories/${item.id}`}
                      className='btn btn-primary flex-grow-1 me-2'
                    >
                      {intl.formatMessage({ id: 'Table.Edit' })}
                    </Link>
                  )}

                  {check.delete && (
                    <button
                      onClick={() => deleteCategory(item.id)}
                      className='btn btn-danger flex-grow-1'
                    >
                      {intl.formatMessage({ id: 'Table.Delete' })}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{ width: '100%', height: '90vh', marginTop: '1vh' }}
          />
        )}

        {/* Pagination */}
        <div className='mt-6 d-flex justify-content-center'>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </div>
      </div>
      <ImportCats handleClose={setImport} get_data={getData} show={Import} />
      
    </motion.nav>
  )
}

export default CategoriesHome
