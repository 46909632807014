import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { KTSVG } from '../../../../_metronic/helpers';
import { MdCloudUpload } from 'react-icons/md';
import useAuthContext from '../../../Auth/AuthContext';
import JoditTextEditor from "../../../../_metronic/partials/widgets/React_Table/JoditTextEditor";
import { FormControl, MenuItem, Select } from '@mui/material';
import ProductsAutoComplete from '../../../../_metronic/partials/widgets/AutoComplete/ProductsAutoComplete';
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

export const EditLoyaltyPoint = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const { check_role } = useAuthContext()
    const [cats, setCats] = useState([]);
    const [user_value, setUSER_Value] = useState(null);
    const desEnRef = useRef("");
    const desArRef = useRef("");
    const [Date, setDate] = useState(moment().format('YYYY-MM-01'))
    const { id } = useParams();
    const [editorLoaded, setEditorLoaded] = useState(false); // Tracks if editor should load

    const background_types = [
        { value: "img", label: "Background Image" },
        { value: "color", label: "Background Color" },
    ]

    const task_types = [
        { value: "buy_with_count", label: "Buy Products With Count" },
        { value: "buy_with_total", label: "Buy Products With Total" },
        { value: "review", label: "Add Review" },
    ]
    const task_to_types = [
        { value: "category", label: "Category" },
        { value: "product", label: "Product" },
    ]

    const [data, setData] = useState({
        background_type: 'img',
        img: null,
        color: null,
        name_en: null,
        name_ar: null,
        des_en: desEnRef,
        des_ar: desArRef,
        slug: null,
        points: 0,
        task_type: 'buy_with_count',
        count: 0,
        total: 0,
        task_to_type: 'category',
        cat_id: null,
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const changeHandler_iamge = (event) => {
        const name = event.target.name;
        setData((prevData) => ({ ...prevData, [name]: event.target.files[0] }));
    };

    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('enctype', 'multipart/form-data');
        Object.entries(data).forEach(([key, value]) => {
            if (key === "des_en" || key === "des_ar") {
                formData.append(key, value.current); // Use ref's current value
            } else {
                formData.append(key, value);
            }
        });
        formData.append('active_date', Date);
        if (data.task_to_type === 'product') {
            formData.append('item_id', user_value?.id);
        }
        formData.append('_method', 'PATCH');

        try {
            await axiosInstance.post(`/admin-user-tasks/${id}`, formData).then(response => {
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setLoading(false)
                setErrors([])
                navigate(-1)
            })
        } catch (e) {
            console.log(e.response.data.errors);
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }
    }

    const get_data = async () => {
        const { data } = await axiosInstance.get(`/admin-user-tasks/${id}`)
        console.log('data', data);
        const response = data
        desEnRef.current = response.des_en;
        desArRef.current = response.des_ar;
        setData({
            background_type: response.background_type,
            img: null,
            img_name: response.img,
            color: response.background_color,
            name_en: response.name_en,
            name_ar: response.name_ar,
            des_en: desEnRef,
            des_ar: desArRef,
            slug: response.slug,
            points: response.points,
            task_type: response.task_type,
            count: response.count,
            total: response.total,
            task_to_type: response.task_to_type,
            cat_id: response.cat_id,
        })
        console.log('response_data', desEnRef.current);
        setDate(response.active_date)
        if (response.task_to_type === 'product') {
            setUSER_Value(response.item)
        }
        setEditorLoaded(true);

    }
    const get_cats = async () => {
        const { data } = await axiosInstance.get('/admin-cats')
        setCats(data)
        setData((prevData) => ({ ...prevData, cat_id: data[0].id }));
    }

    useEffect(() => {
        if (!check_role('add-LoyaltyPoints')) {
            navigate('/')
        }
        get_cats()
        get_data()

    }, [])

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'LoyaltyPoints.Create' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.LoyaltyPoint' })}</li>
                    </ul>
                </div>
                <div>
                    <button onClick={() => navigate(-1)} type='button' className='btn btn-primary' >
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
                        {intl.formatMessage({ id: 'Form.GoBack' })}
                    </button>
                </div>
            </div>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">


                    <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.Background_Type' })} </h2>
                                </div>
                            </div>

                            <div className="card-body text-center pt-0">
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="background_type"
                                        onChange={handleChange}
                                        value={data.background_type}
                                    >
                                        {background_types.map((row, key) => (
                                            <MenuItem key={key} value={row.value}>
                                                {row.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        {data.background_type === "img" && (
                            <div className="card card-flush py-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>{intl.formatMessage({ id: 'Form.ImageContent' })} </h2>
                                    </div>
                                </div>

                                <div className="card-body text-center pt-0">
                                    <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                                        <div className="file" >
                                            <form onClick={() => document.querySelector(".input-field2").click()} >
                                                <input type="file" accept='image/*' className='input-field2' name='img' hidden
                                                    onChange={changeHandler_iamge}
                                                />
                                                {data.img === null && data.img_name ?
                                                    <img src={process.env.REACT_APP_IMAGE_PATH + data.img_name} width={150} height={150} alt='' />
                                                    :
                                                    data.img ?
                                                        <img src={URL.createObjectURL(data.img)} width={150} height={150} alt='' />
                                                        :
                                                        <>
                                                            <MdCloudUpload color='#1475cf' size={60} />
                                                            <p> {intl.formatMessage({ id: 'Form.BrowseFilestoupload' })} </p>
                                                        </>
                                                }
                                            </form>
                                            {errors.img &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.img}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data.background_type === "color" && (
                            <div className="card card-flush py-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>{intl.formatMessage({ id: 'Form.Color' })} </h2>
                                    </div>
                                </div>

                                <div className="card-body text-center pt-0">
                                    <div className="image-input  image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true" style={{ width: '200px' }}>
                                        <div className="file" >
                                            <input type="color"
                                                className='form-control w-100'
                                                name='color'
                                                onChange={handleChange}
                                                value={data.color}
                                                style={{ minHeight: '60px', width: '200px' }}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                                </div>
                            </div>


                            <div className="card-body pt-0">
                                <div className="row flex-between mb-5">

                                    <div className="fv-row col-6 my-3">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Task_Type' })}  </label>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label2"
                                                id="demo-simple-select"
                                                name='task_type'
                                                onChange={handleChange}
                                                value={data.task_type}
                                            >
                                                {task_types.map((row, key) => (
                                                    <MenuItem key={key} value={row.value}>
                                                        {row.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="fv-row col-6 my-3">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Date' })}  </label>
                                        <Flatpickr
                                            value={Date}
                                            onChange={([Date]) => {
                                                setDate(moment(Date).format('YYYY-MM-DD'))
                                            }}
                                            className='form-control py-4 text-center pt-5 bg-secondary'
                                            placeholder='Pick date'
                                        />
                                    </div>

                                    <div className="fv-row col-6 my-3">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Category/Product' })}  </label>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label2"
                                                id="demo-simple-select"
                                                name='task_to_type'
                                                onChange={handleChange}
                                                value={data.task_to_type}
                                            >
                                                {task_to_types.map((row, key) => (
                                                    <MenuItem key={key} value={row.value}>
                                                        {row.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>



                                    {data.task_to_type === "category" && (
                                        <div className="fv-row col-6 my-3">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.Select_cateory' })}  </label>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label2"
                                                    id="demo-simple-select"
                                                    name='cat_id'
                                                    onChange={handleChange}
                                                    value={data.cat_id}
                                                >
                                                    {cats.map((row, key) => (
                                                        <MenuItem key={key} value={row.id}>
                                                            {row.name_en}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    )}

                                    {data.task_to_type === "product" && (
                                        <div className="fv-row col-6 my-3">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.Select_Product' })}  </label>
                                            <ProductsAutoComplete value={user_value} setValue={setUSER_Value} />
                                            {errors.item_id &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.item_id}</span>
                                                    </div>
                                                </div>
                                            }
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                        </div>
                                    )}

                                </div>

                                <div className="mb-3 fv-row fv-plugins-icon-container">
                                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.NameEN' })}  </label>
                                    <input type="text" name="name_en" className="form-control mb-2" value={data.name_en} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameEN' })} />
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                    {errors.name_en &&
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{errors.name_en}</span>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="mb-3 fv-row fv-plugins-icon-container">
                                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.NameAR' })}</label>
                                    <input type="text" name="name_ar" className="form-control mb-2" value={data.name_ar} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameAR' })} />
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                    {errors.name_ar &&
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{errors.name_ar}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row flex-between mb-5">

                                    <div className="mb-3 fv-row col-6 fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Points' })}</label>
                                        <input type="text" name="points" className="form-control mb-2" value={data.points} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameAR' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.points &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.points}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {data.task_type === "buy_with_count" && (

                                        <div className="mb-3 fv-row col-6 fv-plugins-icon-container">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.Count' })}</label>
                                            <input type="text" name="count" className="form-control mb-2" value={data.count} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameAR' })} />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            {errors.count &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.count}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )}

                                    {data.task_type === "buy_with_total" && (

                                        <div className="mb-3 fv-row col-6 fv-plugins-icon-container">
                                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.Total' })}</label>
                                            <input type="text" name="total" className="form-control mb-2" value={data.total} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameAR' })} />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            {errors.total &&
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{errors.total}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className="mb-3 fv-row fv-plugins-icon-container">
                                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.Slug' })}</label>
                                    <input type="text" name="slug" className="form-control mb-2" value={data.slug} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Slug' })} />
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                    {errors.slug &&
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{errors.slug}</span>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.ItemDetails' })}</h2>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-12 col-sm-12">
                                        <label className="form-label">{intl.formatMessage({ id: 'Form.DiscretionEN' })}</label>
                                        {editorLoaded && (
                                            <JoditTextEditor
                                                initialValue={desEnRef.current} // Load initial content
                                                onChange={(content) => (desEnRef.current = content)} // Store in ref without re-rendering
                                            />
                                        )}

                                    </div>
                                    <div className="mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-12 col-sm-12">
                                        <label className="form-label">{intl.formatMessage({ id: 'Form.DiscretionAR' })}</label>
                                        {editorLoaded && (
                                            <JoditTextEditor
                                                initialValue={desArRef.current}
                                                onChange={(content) => (desArRef.current = content)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end ">
                            <button type="submit" className="btn btn-primary">
                                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </motion.nav>
    )
}
