import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';
import { MdCloudUpload } from 'react-icons/md';
import useAuthContext from '../../../Auth/AuthContext';
import JoditTextEditor from "../../../../_metronic/partials/widgets/React_Table/JoditTextEditor";

export const UpdateProducts = () => {
  const { id } = useParams();
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);
  const [fileName, setFileName] = useState("No selected file")
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [cats, setCats] = useState([]);
  const { check_role } = useAuthContext()
  const types = [
    { id: 'stock', name: 'Stock' },
    { id: 'api', name: 'Api' },
  ];
  const desEnRef = useRef(""); // Store editor content without re-renders
  const desArRef = useRef("");
  const [editorLoaded, setEditorLoaded] = useState(false); // Tracks if editor should load

  const [data, setData] = useState({
    type: 'banner1',
    img: null,
    price: null,
    sale_priority: null,
    min_stock: null,

    cat_id: null,
    country_id: null,
    name_en: null,
    name_ar: null,
    des_en: desEnRef,
    des_ar: desArRef,
    meta_tag_en: null,
    meta_description_en: null,
    meta_tag_ar: null,
    meta_description_ar: null,
  });

  const [check, setCheck] = useState({
    edit: false,
    edit_price: false,
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const changeHandler_iamge = (event) => {
    const name = event.target.name;
    setData((prevData) => ({ ...prevData, [name]: event.target.files[0] }));
    setFileName(event.target.files[0].name)
  };

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data');
    Object.entries(data).forEach(([key, value]) => {
      if (key === "des_en" || key === "des_ar") {
        formData.append(key, value.current); // Use ref's current value
      } else {
        formData.append(key, value);
      }
    });
    formData.append('_method', 'PATCH');
    try {
      await axiosInstance.post(`/admin-items/${id}`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading(false)
        setErrors([])
        navigate(`/Products/Products`)
      })
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }


  const get_countries = async () => {
    const { data } = await axiosInstance.get('/admin-countries')
    setCountries(data)
  }

  const get_cats = async () => {
    const { data } = await axiosInstance.get('/admin-cats')
    setCats(data)
  }

  const get_data = async () => {
    await axiosInstance.get(`/admin-items/${id}`).then(function (res) {
      const response = res.data;
      desEnRef.current = response.des_en;
      desArRef.current = response.des_ar;
      setData({
        img_name: response.img,
        img: null,
        type: response.type,
        cat_id: response.cat_id,
        country_id: response.country_id,
        price: response.price,
        sale_priority: response.sale_priority,
        min_stock: response.min_stock,
        name_en: response.name_en,
        name_ar: response.name_ar,
        des_en: desEnRef,
        des_ar: desArRef,
        meta_tag_en: response.meta_tag_en,
        meta_description_en: response.meta_description_en,
        meta_tag_ar: response.meta_tag_ar,
        meta_description_ar: response.meta_description_ar,

 
        price: response.price,
        type: response.type,
        slug: response.slug,
        cost: response.cost,
        payment_fees_cost: response.payment_fees_cost,
      });
    })
    setEditorLoaded(true);

  }



  useEffect(() => {
    if (!check_role('show-products')) {
      navigate('/')
    }
    get_countries()
    get_cats()

    get_data()
    setCheck({
      edit: check_role('edit-products'),
      edit_price: check_role('edit-price-products'),
    })
  }, [])

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Banner.Edit' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Banners' })}</li>
          </ul>
        </div>
        <div>
          <Link to='/Products/Products' type='button' className='btn btn-primary' >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Form.GoBack' })}
          </Link>
        </div>
      </div>
      <div id="kt_app_content_container" className="app-container container-xxl">
        <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
          <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.ImageContent' })} </h2>
                </div>
              </div>

              <div className="card-body text-center pt-0">
                <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                  <div className="file" >
                    <form onClick={() => document.querySelector(".input-field2").click()} >
                      <input type="file" accept='image/*' className='input-field2' name='img' hidden
                        onChange={changeHandler_iamge}
                      />
                      {data.img === null && data.img_name ?
                        <img src={process.env.REACT_APP_IMAGE_PATH + data.img_name} width={150} height={150} alt={fileName} />
                        :
                        data.img ?
                          <img src={URL.createObjectURL(data.img)} width={150} height={150} alt={fileName} />
                          :
                          <>
                            <MdCloudUpload color='#1475cf' size={60} />
                            <p> {intl.formatMessage({ id: 'Form.BrowseFilestoupload' })} </p>
                          </>
                      }
                    </form>
                    {errors.img &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.img}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">

            <div className="card card-flush py-4" data-select2-id="select2-data-132-hyjc">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.Types' })} </h2>
                </div>
              </div>
              <div className="card-body py-0">
                <div className="row flex-between">
                  <div className="col-4">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.Select_cateory' })}  </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select"
                        name='cat_id'
                        onChange={handleChange}
                        value={data.cat_id}
                      >
                        {cats.map((row, key) => (
                          <MenuItem key={key} value={row.id}>
                            {row.name_en}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.Select_Country' })}  </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name='country_id'
                        onChange={handleChange}
                        value={data.country_id}
                      >
                        {countries.map((row, key) => (
                          <MenuItem key={key} value={row.id}>
                            {row.name_en}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.sale_priority' })}  </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="sale_priority"
                        onChange={handleChange}
                        value={data.sale_priority}
                      >
                        {types.map((row, key) => (
                          <MenuItem key={key} value={row.id}>
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                </div>
              </div>

              <div className="card-body pt-0">
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="required form-label"> {intl.formatMessage({ id: 'Form.NameEN' })}  </label>
                  <input type="text" name="name_en" className="form-control mb-2" value={data.name_en} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameEN' })} />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.name_en &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.name_en}</span>
                      </div>
                    </div>
                  }
                </div>

                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="required form-label"> {intl.formatMessage({ id: 'Form.NameAR' })}</label>
                  <input type="text" name="name_ar" className="form-control mb-2" value={data.name_ar} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameAR' })} />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.name_ar &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.name_ar}</span>
                      </div>
                    </div>
                  }
                </div>


                {check.edit_price &&
                  <div>
                    {data.type === 'binance' ?
                      <>
                        <div className="row flex-between">
                          <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.Cost' })}</label>
                            <input type="number" name="cost" className="form-control mb-2" value={data.cost} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Cost' })} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            {errors.cost &&
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.cost}</span>
                                </div>
                              </div>
                            }
                          </div>

                          <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.Price' })}</label>
                            <input type="number" name="price" className="form-control mb-2" value={data.price} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Price' })} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            {errors.price &&
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.price}</span>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </>
                      :

                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Price' })}</label>
                        <input type="number" name="price" className="form-control mb-2" value={data.price} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Price' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.price &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.price}</span>
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }

                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="required form-label"> {intl.formatMessage({ id: 'Form.Payment_fee' })}</label>
                  <input type="text" name="payment_fees_cost" className="form-control mb-2" value={data.payment_fees_cost} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Payment_fee' })} />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.payment_fees_cost &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.payment_fees_cost}</span>
                      </div>
                    </div>
                  }
                </div>
                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="required form-label"> {intl.formatMessage({ id: 'Form.Slug' })}</label>
                  <input type="text" name="slug" className="form-control mb-2" value={data.slug} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Slug' })} />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.slug &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.slug}</span>
                      </div>
                    </div>
                  }
                </div>

                <div className="mb-3 fv-row fv-plugins-icon-container">
                  <label className="required form-label"> {intl.formatMessage({ id: 'Form.mini_stock' })}</label>
                  <input type="text" name="min_stock" className="form-control mb-2" value={data.min_stock} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.mini_stock' })} />
                  <div className="fv-plugins-message-container invalid-feedback" />
                  {errors.min_stock &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.min_stock}</span>
                      </div>
                    </div>
                  }
                </div>

              </div>
            </div>

            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.ItemDetails' })}</h2>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="row">
                <div className="mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-12 col-sm-12">
                    <label className="form-label">{intl.formatMessage({ id: 'Form.DiscretionEN' })}</label>
                    {editorLoaded && (
                      <JoditTextEditor
                        initialValue={desEnRef.current} // Load initial content
                        onChange={(content) => (desEnRef.current = content)} // Store in ref without re-rendering
                      />
                    )}

                  </div>
                  <div className="mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-12 col-sm-12">
                    <label className="form-label">{intl.formatMessage({ id: 'Form.DiscretionAR' })}</label>
                    {editorLoaded && (
                      <JoditTextEditor
                        initialValue={desArRef.current}
                        onChange={(content) => (desArRef.current = content)}
                      />
                    )}
                  </div>
                  <div className="mb-3 col-lg-6 fv-row fv-plugins-icon-container">
                    <label className=" form-label"> {intl.formatMessage({ id: 'Form.Meta_tag' })} EN  </label>
                    <input type="text" name="meta_tag_en" className="form-control mb-2" value={data.meta_tag_en} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Meta_tag' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.meta_tag_en &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.meta_tag_en}</span>
                        </div>
                      </div>
                    }
                  </div>
                  
                  <div className="mb-3 col-lg-6 fv-row fv-plugins-icon-container">
                    <label className=" form-label"> {intl.formatMessage({ id: 'Form.Meta_tag' })} AR  </label>
                    <input type="text" name="meta_tag_ar" className="form-control mb-2" value={data.meta_tag_ar} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Meta_tag' })} />
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.meta_tag_ar &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.meta_tag_ar}</span>
                        </div>
                      </div>
                    }
                  </div>

                  <div className="mb-3 fv-row  col-lg-6 fv-plugins-icon-container">
                    <label className=" form-label"> {intl.formatMessage({ id: 'Form.Meta_description' })} EN</label>
                    <textarea name="meta_description_en" className='form-control mb-2'
                      maxLength="250"
                      onChange={handleChange}
                      cols="30" rows="5" value={data.meta_description_en}>
                    </textarea>
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.meta_description_en &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.meta_description_en}</span>
                        </div>
                      </div>
                    }
                  </div>

                  <div className="mb-3 fv-row  col-lg-6 fv-plugins-icon-container">
                    <label className=" form-label"> {intl.formatMessage({ id: 'Form.Meta_description' })} AR</label>
                    <textarea name="meta_description_ar" className='form-control mb-2'
                      maxLength="250"
                      onChange={handleChange}
                      cols="30" rows="5" value={data.meta_description_ar}>
                    </textarea>
                    <div className="fv-plugins-message-container invalid-feedback" />
                    {errors.meta_description_ar &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.meta_description_ar}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end ">
              {check_role('edit-products') && (
                <button type="submit" className="btn btn-primary">
                  {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}

            </div>
          </div>
        </form>
      </div >

    </motion.nav >
  )
}
