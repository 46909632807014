import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton, Pagination} from '@mui/material' // Import Pagination from MUI
import useAuthContext from '../../../Auth/AuthContext'

const HomeServiceFees = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState([]) // Initialize Items as an empty array to avoid null issues

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })


  // Define the columns for the table view
  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: (row) => <div className='w-100 flex-center'># {row.row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Name'}),
      accessor: 'name',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original.name}</div>,
    },
    {
      Header: 'enable',
      accessor: 'status',
      Cell: (row) => (
        <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
          {check.edit && (
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={row.row.original.status}
              onClick={() => edit_status(!row.row.original.status, row.row.original.id)}
            />
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Edit-ServiceFees/${row.row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => Delete(row.row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Add-ServiceFees' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'ServiceFees.Create'})}
          </Link>
        </div>
      )}
    </div>
  )

  const get_data = async () => {
    setLoading(true)
    try {
      const {data} = await axiosInstance.get('/admin-servicefees')
      setItems(data || []) // Ensure Items is set to an array even if data is empty or null
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.delete(`/admin-servicefees/${id}`)
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const edit_status = async (value, id) => {
    let endpoint = `/change-servicefees-status/${id}/${value}`
    try {
      await axiosInstance.get(endpoint)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setCheck({
      browse: check_role('browse-servicefees'),
      add: check_role('add-servicefees'),
      show: check_role('show-servicefees'),
      edit: check_role('edit-servicefees'),
      delete: check_role('delete-servicefees'),
    })

    if (!check_role('browse-servicefees')) {
      navigate('/')
    }
    get_data()
  }, [])


  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Service_Fees'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Service_Fees'})}
            </li>
          </ul>
        </div>
      </div>

      {/* Table view on large screens */}
      <div className='d-none d-lg-block'>
        { !loading ? (
          <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
          />
        )}
      </div>
    </motion.nav>
  )
}

export default HomeServiceFees
