import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import moment from 'moment';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable';
import useAuthContext from '../../../Auth/AuthContext';

export const UpdateDiscount = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);
  const [Items, setItems] = useState([]);
  const navigate = useNavigate();
  const [imported, setImported] = useState([]);
  const { id } = useParams();

  const { check_role } = useAuthContext()

  const [data, setData] = useState({
    name: null,
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'fixed',
    discount_type: 'discount',
    percentage: 0,
    discount: 0,
    up_to: 0,
    coupon: 0,
  });

  const [step, setStep] = useState(1)

  const discount_types = [
    { id: 'discount', name: 'Discount' },
    { id: 'coupon', name: 'Coupon' },
  ]
  const types = [
    {id: 'fixed', name: 'percentage'},
    {id: 'variable', name: 'direct discount'},
  ]
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data');
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('data', JSON.stringify(imported));
    formData.append('_method', 'Patch');
    try {
      await axiosInstance.post(`/admin-discounts/${id}`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading(false)
        setErrors([])
        navigate(`/Discounts/Discounts`)
      })
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  const get_data = async () => {
    setItems([])
    const [discountResponse, itemsDiscountResponse] = await Promise.all([
      axiosInstance.get(`/admin-discounts/${id}`),
      axiosInstance.get('/admin-items-discounts')
    ]);

    const discountData = discountResponse.data;
    setData({
      name: discountData.name,
      start_date: discountData.start_date,
      end_date: discountData.end_date,
      type: discountData.type,
      discount_type: discountData.discount_type,
      percentage: discountData.percentage ?? 0,
      discount: discountData.discount ?? 0,
      up_to: discountData.up_to ?? 0,
      coupon: discountData.coupon ?? 0,
    });

    if (discountData.items) {
      const allItems = discountData.items.map(item => ({
        id: item.id,
        name_en: item.name_en,
        name_ar: item.name_ar,
      }));
      setImported(allItems);

      setItems(prevItems => [
        ...prevItems,
        ...discountData.items,
      ]);
    }

    setItems(prevItems => [
      ...prevItems,
      ...itemsDiscountResponse.data,
    ]);

  };

   const TruncateString = ({ text }) => {
    const truncatedText = text ? text.slice(0, 50) : '';
    return truncatedText;
  };

  const add_item = (item) => {
    const rowsInput = {
      id: item.id,
      name_en: item.name_en,
      name_ar: item.name_ar,
    };
    setImported((prevImported) => [...prevImported, rowsInput]);
  };

  const remove_item = (item) => {
    setImported((prevImported) => prevImported.filter(importedItem => importedItem.id !== item.id));
  };

  const updateImportedItem = (id, key, value) => {
    setImported((prevImported) =>
      prevImported.map((item) =>
        item.id === id ? { ...item, [key]: value } : item
      )
    );
  };

  const handle_change = (e, item) => {
    if (e.target.checked) {
      add_item(item);
    } else {
      remove_item(item);
    }
  };

  const isChecked = (id) => {
    return imported.some(importedItem => importedItem.id === id);
  };

  const import_all = () => {
    setImported([])
    const allItems = Items.map(item => ({
      id: item.id,
      name_en: item.name_en,
      name_ar: item.name_ar,
    }));
    setImported(allItems);
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'productID',
      Cell: row => (
        <div className="w-100 flex-center">
          <div className='form-check form-check-sm form-check-custom form-check-solid flex-center'>
            <input
              className='form-check-input widget-9-check me-2'
              onChange={(e) => { handle_change(e, row.row.original) }}
              type='checkbox'
              checked={isChecked(row.row.original.id)}
            />
            # {row.row.original.id}
          </div>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Supplier' }), accessor: 'type',
      Cell: row => (
        <div className=" w-100 flex-center">
          {row.row.original.type ?? '---'}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Description' }), accessor: 'name_en',
      Cell: row => (
        <div className="w-300 flex-center fs-12 f-bold">
          <div>
            <TruncateString text={row.row.original.name_en} />
          </div>
          <div>
            <TruncateString text={row.row.original.name_ar} />
          </div>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.File' }), accessor: 'File',
      Cell: row => (
        <div className="symbol symbol-50px ">
          <img src={row.row.original.img ? process.env.REACT_APP_IMAGE_PATH + row.row.original.img : toAbsoluteUrl("media/avatars/300-6.jpg")} alt="" />
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Available' }), accessor: 'available',
      Cell: row => (
        <div className="w-100 flex-center">
          {row.row.original.available ?
            <span className="badge badge-light-success">{intl.formatMessage({ id: 'Table.Available' })}</span>
            :
            <span className="badge badge-light-danger">{intl.formatMessage({ id: 'Table.notAvailable' })}</span>
          }
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.General_Inofrmation' }), accessor: 'General_Inofrmation',
      Cell: row => (
        <div className=" flex-center w-200" >
          <div className="row flex-between">
            <div className="col-8 text-start">
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.Price' })}
              </div>
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.Stock' })}
              </div>
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.MiniStock' })}
              </div>
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.SalePriority' })}
              </div>
            </div>
            <div className="col-4 text-start">
              <div className='my-2 fs-12'>
                {': ' + row.row.original.price.toFixed(1)}
              </div>
              <div className='my-2 fs-12'>
                {': ' + row.row.original.stock}
              </div>
              <div className='my-2 fs-12'>
                {': ' + row.row.original.min_stock}
              </div>
              <div className='my-2 fs-12'>
                {': ' + row.row.original.sale_priority}
              </div>
            </div>
          </div>
        </div>
      ),
    },

  ];

  useEffect(() => {
    if (!check_role('show-discounts')) {
      navigate('/')
    }
    get_data();
  }, [])

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Discounts.Edit' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Discounts' })}</li>
          </ul>
        </div>
        <div>
          <Link to='/Discounts/Discounts' type='button' className='btn btn-primary' >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Form.GoBack' })}
          </Link>
        </div>
      </div>
      <div id="kt_app_content_container" className="app-container container-xxl">
        <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            {step === 1 &&
              <>
                <div className="card card-flush py-4">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                    </div>
                  </div>

                  <div className="card-body pt-0">
                    <div className="row flex-between">
                      <div className="col-lg-6 my-5 col-md-6 my-2">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.StartDate' })}  </label>
                        <Flatpickr
                          value={data.start_date}
                          onChange={([start_date]) => {
                            setData((prevData) => ({
                              ...prevData,
                              start_date: moment(start_date).format('YYYY-MM-DD')
                            }));
                          }}
                          className='form-control py-4 text-center pt-5'
                          placeholder='Pick date'
                        />
                      </div>
                      <div className="col-lg-6 my-5 col-md-6 my-2">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.EndDate' })}  </label>
                        <Flatpickr
                          options={{ minDate: data.start_date }}
                          value={data.end_date}
                          onChange={([end_date]) => {
                            setData((prevData) => ({
                              ...prevData,
                              end_date: moment(end_date).format('YYYY-MM-DD')
                            }));
                          }}
                          className='form-control py-4 text-center pt-5'
                          placeholder='Pick date'
                        />
                      </div>

                      <div className=" my-5 col-6 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Name' })}  </label>
                        <input type="text" name="name" className="form-control py-5" value={data.name} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Name' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.name &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.name}</span>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="col-6  my-5 ">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.DiscountType' })}  </label>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='type'
                            onChange={handleChange}
                            value={data.type}
                          >
                            {types.map((row, key) => (
                              <MenuItem key={key} value={row.id}>
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div className="col-4  my-5 ">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.RewordType' })}  </label>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='discount_type'
                            onChange={handleChange}
                            value={data.discount_type}
                          >
                            {discount_types.map((row, key) => (
                              <MenuItem key={key} value={row.id}>
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div> */}
                      {data.type === 'fixed' ?
                        <>
                          <div className=" my-5 fv-row col-6 fv-plugins-icon-container">
                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.Percentage' })}</label>
                            <input type="number" name="percentage" className="form-control py-5" value={data.percentage} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Percentage' })} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            {errors.percentage &&
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.percentage}</span>
                                </div>
                              </div>
                            }
                          </div>
                          <div className=" my-5 fv-row col-6 fv-plugins-icon-container">
                            <label className="required form-label"> {intl.formatMessage({ id: 'Form.UpTo' })}</label>
                            <input type="number" name="up_to" className="form-control py-5" value={data.up_to} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.UpTo' })} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            {errors.up_to &&
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.up_to}</span>
                                </div>
                              </div>
                            }
                          </div>
                        </>
                        :
                        <div className=" my-5 fv-row col-6 fv-plugins-icon-container">
                          <label className="required form-label"> {intl.formatMessage({ id: 'Form.Discount' })}</label>
                          <input type="number" name="discount" className="form-control py-5" value={data.discount} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Discount' })} />
                          <div className="fv-plugins-message-container invalid-feedback" />
                          {errors.discount &&
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.discount}</span>
                              </div>
                            </div>
                          }
                        </div>
                      }

{data.discount_type === 'coupon' && (
                      <div className='col-4 my-5'>
                        <label className='required form-label'>
                          {intl.formatMessage({id: 'Form.CouponCode'})}
                        </label>
                        <input
                          type='text'
                          name='coupon'
                          className='form-control py-5'
                          placeholder={intl.formatMessage({id: 'Form.CouponCode'})}
                          onChange={handleChange}
                          value={data.coupon}
                        />
                      </div>
                    )}
                    </div>
                  </div>
                </div>
                <div className="row flex-end  my-3">

                  <div className="col-2">
                    {step === 1 &&
                      <button onClick={(e) => setStep(step + 1)} type='button' className='w-100 btn btn-light-primary'>
                        {intl.formatMessage({ id: 'Table.Next' })}
                        <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-2 me-0 ms-3' />
                      </button>
                    }
                  </div>
                </div>
              </>
            }
            {step === 2 &&
              <>
                <ReactTable columns={columns} data={Items} customComponent={<></>} />
                <div className="row flex-between  my-3">
                  <div className="col-2">
                    <button onClick={(e) => setStep(step - 1)} type='button' className='w-100 btn btn-secondary'>
                      <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
                      {intl.formatMessage({ id: 'Table.Back' })}
                    </button>
                  </div>
                  {check_role('edit-discounts') && (
                    <button type="submit" className="btn col-2 btn-primary">
                      {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  )}

                </div>
              </>
            }
          </div>
        </form>
      </div >

    </motion.nav >
  )
}
